import { forwardRef, useContext, useEffect, useState } from 'react'
import { motion, Variants } from 'framer-motion'
import cx from 'classnames'

import { SanityProductFragment } from '@data/sanity/queries/types/product'
import { CartContext } from '@lib/cart'
import { hasObject } from '@lib/helpers'
import { Filter, FilterValue } from '@lib/product'
import { getPageUrl, PageType } from '@lib/routes'
import { StringsContext } from '@lib/strings'

import { ButtonColor, ButtonVariant } from '@components/buttons/button'
import ProductAdd from '@blocks/product/product-add'
import ProductGallery from '@blocks/product/product-gallery'
import ProductOption from '@blocks/product/product-option'
import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import SimpleLink from '@components/simple-link'

interface OptionValue {
  name: string
  value: string
}

interface ProductCardProps {
  product: SanityProductFragment
  hasVisuals?: boolean
  showGallery?: boolean
  showThumbnails?: boolean
  showPrice?: boolean
  showOption?: boolean
  showQuickAdd?: boolean
  activeFilters?: Filter[]
  isInline?: boolean
  isFeatured?: boolean
  className?: string
  onClick?: () => void
}

const itemAnimation: Variants = {
  initial: {
    opacity: 0,
  },
  show: (i: number) => ({
    opacity: 1,
    transition: {
      delay: i * 0.05 + 0.4,
      duration: 0.3,
      ease: 'linear',
    },
  }),
  hide: (i: number) => ({
    opacity: 0,
    transition: {
      delay: i * 0.05,
      duration: 0.3,
      ease: 'linear',
    },
  }),
}

/**
 * Gets default product option taking into account filters.
 */
export const getDefaultOptionsWithFilters = (
  product: SanityProductFragment,
  activeFilterValues: FilterValue[]
): OptionValue[] => {
  return activeFilterValues
    .map((filter) => {
      const currentFilter = product.filters?.find(
        ({ forOption, slug }) => slug === filter.value && forOption
      )

      if (!currentFilter) {
        return null
      }

      const option = currentFilter.forOption.split(':')
      const optionValue: OptionValue = { name: option[0], value: option[1] }

      return optionValue
    })
    .filter(Boolean) as OptionValue[]
}

/**
 * Gets the default product variant based on option values.
 */
const getDefaultProductVariant = (
  product: SanityProductFragment,
  optionValues: OptionValue[]
) => {
  const lastOptionValue =
    optionValues.length > 0 ? optionValues[optionValues.length - 1] : null

  const firstOption = product.options?.[0]
  const firstOptionValue = firstOption
    ? ({
        name: firstOption?.name,
        value: firstOption?.values?.[0],
      } as OptionValue)
    : null

  const optionValue = lastOptionValue ?? firstOptionValue

  if (!optionValue) {
    return null
  }

  return (
    product.variants?.find(({ options }) => hasObject(options, optionValue)) ??
    null
  )
}

/**
 * Gets filter values from filters.
 */
const getFilterValues = (filters: Filter[]): FilterValue[] => {
  return (
    filters.flatMap(({ name, values }) =>
      values.map((value) => ({ name, value }))
    ) ?? []
  )
}

/**
 * Gets the default or first product variant based on current active filters.
 */
const getProductVariant = (
  product: SanityProductFragment,
  activeFilters: Filter[]
) => {
  const activeFilterValues = getFilterValues(activeFilters)
  const defaultOptions = getDefaultOptionsWithFilters(
    product,
    activeFilterValues
  )
  const defaultVariant = getDefaultProductVariant(product, defaultOptions)
  const firstVariant = product.variants?.[0] ?? null

  return defaultVariant ?? firstVariant
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    {
      product,
      hasVisuals,
      showGallery,
      showThumbnails,
      showPrice,
      showOption,
      showQuickAdd,
      activeFilters,
      isInline,
      isFeatured,
      className,
      onClick,
    },
    ref
  ) => {
    const strings = useContext(StringsContext)
    const { addItemsToCart } = useContext(CartContext)

    const [activeVariant, setActiveVariant] = useState(() =>
      getProductVariant(product, activeFilters ?? [])
    )

    useEffect(
      () => setActiveVariant(getProductVariant(product, activeFilters ?? [])),
      [activeFilters, product]
    )

    const mainPhotos = product.photos.main ?? []
    const listingPhotos = product.photos.listing ?? []
    const hasGallery = showGallery && mainPhotos.length > 0
    const hasThumbnails = showThumbnails && listingPhotos.length > 0
    const hasQuickAdd = showQuickAdd && !!activeVariant?.inStock

    // Get product URL
    const urlOptions = {
      parameters: {
        variant:
          product.surfaceOption && activeVariant ? `${activeVariant.id}` : '',
      },
    }
    const productUrl = getPageUrl(PageType.PRODUCT, product.slug, urlOptions)

    const handleAddToCart = async () => {
      if (!activeVariant) {
        return
      }

      await addItemsToCart([{ id: activeVariant.id, quantity: 1 }])
    }

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx(
          'flex flex-col relative group',
          { 'my-4': isInline },
          className
        )}
      >
        {hasVisuals && activeVariant && (hasGallery || hasThumbnails) && (
          <div className="relative">
            {hasGallery && (
              <div className="relative">
                <ProductGallery
                  photosets={mainPhotos}
                  activeVariant={activeVariant}
                  hasArrows
                  hasDots
                  hasDrag={false}
                />
              </div>
            )}

            {hasThumbnails && (
              <div className="z-0">
                <ProductThumbnail
                  thumbnails={listingPhotos}
                  activeVariant={activeVariant}
                />
              </div>
            )}

            {hasQuickAdd && (
              <div
                className={cx(
                  'absolute bottom-0 inset-x-0 z-30 text-center mx-4 mb-8 opacity-0 invisible translate-y-1/2 transition-all will-change-transform',
                  'group-hover:opacity-100 group-hover:visible group-hover:translate-y-0'
                )}
              >
                <ProductAdd
                  variant={ButtonVariant.FILLED}
                  color={ButtonColor.DEFAULT}
                  onClick={handleAddToCart}
                >
                  {strings.buttonAddToCart}
                </ProductAdd>
              </div>
            )}
          </div>
        )}

        <div
          className={cx('text-center', {
            'mt-4': isInline,
            'mt-6': !isInline,
          })}
        >
          <div>
            <h2
              className={cx('!m-0', {
                'text-2xl': isInline,
                'text-base': isFeatured,
              })}
            >
              <SimpleLink
                className="block no-underline text-current after:block after:absolute after:inset-0 after:z-20"
                href={productUrl}
                onClick={onClick}
                onBeforeInput={onClick}
                tabIndex={0}
              >
                {product.title}
              </SimpleLink>
            </h2>

            {showPrice && (
              <ProductPrice
                className="flex items-center justify-center mt-2"
                price={activeVariant?.price ?? product.price}
                comparePrice={
                  activeVariant?.comparePrice ?? product.comparePrice
                }
                inProductCard
              />
            )}
          </div>

          {showOption && activeVariant && (
            <div className="relative z-30 mt-4">
              {product.options.map(
                (option, index) =>
                  product.surfaceOption &&
                  option.position === parseInt(product.surfaceOption) &&
                  option.values.length > 1 && (
                    <ProductOption
                      key={option.name}
                      position={index}
                      option={option}
                      optionSettings={product.optionSettings ?? []}
                      variants={product.variants ?? []}
                      activeVariant={activeVariant}
                      strictMatch={false}
                      hideLabels
                      onChange={setActiveVariant}
                      inProductCard
                    />
                  )
              )}
            </div>
          )}
        </div>
      </motion.div>
    )
  }
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
