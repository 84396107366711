import { Children, ReactNode, useContext, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { useKeenSlider } from 'keen-slider/react'
import cx from 'classnames'

import { flipAnimation } from '@lib/animate'
import { StringsContext } from '@lib/strings'

import Icon from '@components/icon'

interface PhotoCarouselProps {
  id: string
  children: ReactNode
  hasArrows?: boolean
  hasDots?: boolean
  hasCounter?: boolean
  hasDrag?: boolean
  cornerControls?: boolean
  className?: string
}

const PhotoCarousel = ({
  id,
  hasArrows,
  hasDots,
  hasCounter,
  hasDrag = true,
  cornerControls,
  className,
  children,
}: PhotoCarouselProps) => {
  const strings = useContext(StringsContext)

  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    selector: '.carousel-slide',
    loop: true,
    defaultAnimation: { duration: 800 },
    dragSpeed: 0.8,
    drag: hasDrag,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  const slideCount = Children.map(children, (_, index) => index)?.length ?? 0

  return (
    <div className={cx('relative w-full bg-transparent', className)}>
      <div
        ref={sliderRef}
        className={cx(
          'flex relative overflow-hidden will-change-transform touch-action touch-action-pan-y items-center',
          {
            'cursor-grab active:cursor-grabbing': hasDrag,
          }
        )}
      >
        {Children.map(children, (child, index) => (
          <div
            className="carousel-slide relative flex flex-grow-0 flex-shrink-0 w-full min-h-full overflow-hidden justify-center"
            key={index}
          >
            {child}
          </div>
        ))}
      </div>

      {slideCount > 1 && (
        <div
          className={cx(
            'flex absolute bottom-0 inset-x-0 transform translate-y-1/2 pointer-events-none',
            {
              'justify-center translate-y-1/2': !cornerControls,
              'justify-start translate-y-0': cornerControls,
            }
          )}
        >
          <div
            className={cx(
              'flex flex-row items-center rounded-full p-1 pointer-events-auto',
              {
                'relative bg-pageBG border': !cornerControls,
                'static bg-transparent border-none': cornerControls,
              }
            )}
          >
            {hasArrows && (
              <button
                onClick={() => slider.current?.prev()}
                aria-label={strings.carouselLeftArrowLabel}
                className={cx(
                  'w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300 hover:bg-pageBG hover:bg-opacity-20',
                  { 'absolute bottom-3 right-11': cornerControls }
                )}
              >
                <Icon name="ArrowLeft" id={`prev-${id}`} className="block" />
              </button>
            )}

            <div
              className={cx(
                'flex items-center justify-center relative h-8 mx-2',
                { 'mx-0 pl-1 pb-3': cornerControls }
              )}
            >
              {hasDots && (
                <div
                  className={cx('relative flex items-center h-8', {
                    'ml-3': cornerControls,
                  })}
                >
                  {[...Array(slideCount).keys()].map((index) => (
                    <button
                      key={index}
                      onClick={() => slider.current?.moveToIdx(index)}
                      aria-label={strings.carouselDotLabel.replace(
                        /{index}/gi,
                        (index + 1).toString()
                      )}
                      className={cx(
                        'p-1 bg-transparent',
                        'before:block before:relative before:w-2 before:h-2 before:bg-pageBG before:rounded-full before:opacity-30',
                        'before:transition-carousel-dots',
                        'hover:before:opacity-100',
                        {
                          'before:opacity-100 transform scale-150':
                            currentSlide === index,
                        }
                      )}
                    />
                  ))}
                </div>
              )}

              {hasCounter && (
                <div
                  className={cx('relative grid gap-px rounded-full', {
                    'grid-cols-2 h-8 bg-pageBG text-pageText': !cornerControls,
                    'grid-cols-3 h-auto bg-transparent text-pageText select-none':
                      cornerControls,
                  })}
                >
                  {!cornerControls && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 inset-y-3 w-px bg-pageText opacity-30" />
                  )}
                  <div
                    className={cx('relative flex flex-col p-2 pl-3', {
                      'text-xs font-semibold overflow-hidden': !cornerControls,
                      'px-0 text-base font-normal text-center overflow-visible':
                        cornerControls,
                    })}
                  >
                    <div className="relative overflow-hidden">
                      <AnimatePresence initial={false}>
                        <motion.span
                          key={currentSlide + 1}
                          initial="hide"
                          animate="show"
                          exit="hide"
                          variants={flipAnimation}
                          className="block will-change-transform absolute inset-x-0 top-0 last:static last:inset-x-auto last:top-auto"
                        >
                          {currentSlide + 1}
                        </motion.span>
                      </AnimatePresence>
                    </div>
                  </div>

                  {cornerControls && (
                    <div className="relative flex flex-col p-2 px-0 text-base font-normal text-center overflow-visible">
                      <span>{strings.carouselCounterText}</span>
                    </div>
                  )}

                  <div
                    className={cx('relative flex flex-col p-2 pr-3', {
                      'text-xs font-semibold overflow-hidden': !cornerControls,
                      'px-0 text-base font-normal text-center overflow-visible':
                        cornerControls,
                    })}
                  >
                    <span>{slideCount}</span>
                  </div>
                </div>
              )}
            </div>

            {hasArrows && (
              <button
                onClick={() => slider.current?.next()}
                aria-label={strings.carouselRightArrowLabel}
                className={cx(
                  'w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300 hover:bg-pageBG hover:bg-opacity-20',
                  { 'absolute bottom-3 right-3': cornerControls }
                )}
              >
                <Icon name="ArrowRight" id={`next-${id}`} className="block" />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default PhotoCarousel
