import { useContext } from 'react'
import cx from 'classnames'

import { SanityLink } from '@data/sanity/queries/types/content'
import { PhotoImage } from '@lib/image'
import { PageType } from '@lib/routes'
import { StringsContext } from '@lib/strings'

import CustomLink from '@components/link'
import Photo from '@components/photo'

interface LogoLinkProps {
  id: string
  image: PhotoImage
  className?: string
}

const LogoLink = ({ id, image, className }: LogoLinkProps) => {
  const strings = useContext(StringsContext)

  const link: SanityLink = {
    page: { id: `home-page-link-${id}`, type: PageType.HOME_PAGE },
  }

  return (
    <div
      className={cx(
        'flex flex-col justify-center items-center z-10 transition-opacity',
        className
      )}
    >
      <div className="flex flex-col justify-center items-center py-4 w-full">
        <CustomLink link={link} aria-label={strings.goHomeLabel}>
          <Photo image={image} layout="fixed" priority={true} />
        </CustomLink>
      </div>
    </div>
  )
}

export default LogoLink
