import { AnimatePresence, motion, Variants } from 'framer-motion'

import {
  SanityProductListingPhoto,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { hasObject } from '@lib/helpers'

import Photo from '@components/photo'

const thumbAnimation: Variants = {
  show: {
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'linear',
      when: 'beforeChildren',
    },
  },
  hide: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'linear',
      when: 'afterChildren',
    },
  },
}

interface ProductThumbnailProps {
  thumbnails: SanityProductListingPhoto[]
  activeVariant: SanityProductVariantFragment
}

const ProductThumbnail = ({
  thumbnails,
  activeVariant,
}: ProductThumbnailProps) => {
  const activeVariantThumbnail = thumbnails.find((thumbnail) => {
    if (!thumbnail.forOption) {
      return false
    }

    const option = {
      name: thumbnail.forOption.split(':')[0],
      value: thumbnail.forOption.split(':')[1],
    }

    return !!option.value && hasObject(activeVariant.options, option)
  })
  const defaultThumbnail = thumbnails.find((thumbnail) => !thumbnail.forOption)
  const thumbnail = activeVariantThumbnail ?? defaultThumbnail

  if (!thumbnail) {
    return null
  }

  const id = `${thumbnail.default.asset?.assetId}${
    thumbnail.hover?.asset?.assetId ?? ''
  }`

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={id}
        initial="hide"
        animate="show"
        exit="hide"
        variants={thumbAnimation}
        className="relative overflow-hidden"
      >
        <Photo
          image={thumbnail.default}
          layout="responsive"
          sizes="(min-width: 1200px) 33vw, (min-width: 768px) 50vw, 100vw"
        />

        {thumbnail.hover && (
          <Photo
            image={thumbnail.hover}
            layout="responsive"
            sizes="(min-width: 1200px) 33vw, (min-width: 768px) 50vw, 100vw"
            className="absolute inset-0 z-10 opacity-0 invisible transition-all group-hover:opacity-100 group-hover:visible"
          />
        )}
      </motion.div>
    </AnimatePresence>
  )
}

export default ProductThumbnail
