import { ReactNode } from 'react'
import cx from 'classnames'
import NextLink from 'next/link'

import Icon from '@components/icon'

interface AccountButtonProps {
  id: string
  href: string
  className?: string
  children?: ReactNode
  showIcon?: boolean
}

const AccountButton = ({
  id,
  href,
  className,
  children,
  showIcon,
}: AccountButtonProps) => {
  return (
    <NextLink
      href={href}
      className={cx(
        'no-underline cursor-pointer font-inherit flex items-center relative bg-transparent',
        className
      )}
    >
      {showIcon && (
        <Icon
          id={id}
          name="Profile"
          className={cx('text-current text-[28px]', {
            'mr-2': children,
          })}
        />
      )}
      {children}
    </NextLink>
  )
}

export default AccountButton
